import { create } from 'zustand';
import AxiosInstance, { AxiosRequestConfig } from 'axios';
import { Meta, Product } from './types';
import { ThumbnailData } from '@/types/products/ProductsApiResponse';
import { OptionTypes } from '@/types';
import { BASE_URL } from '@/utils/constants';
import fetchAPI from '@/utils/api';
import { VarientsBySeries } from '@/components/product/ProductSpecificationPage';

interface ProductState {
  products: Product[] | any[];
  product: Product | any;
  productCategory: any;
  assets: any;
  galleries: any;
  thumbnail?: ThumbnailData | any;
  variants: any[];
  isLoading: boolean;
  isError: boolean;
  getProducts: (params: any) => Promise<void>;
  getProduct: (params: any) => Promise<void>;
  getProductCategory: (params: any) => Promise<void>;
  isResetProduct: () => void;
  isResetProducts: () => void;
  getRelatedProducts: (params: any) => Promise<void>;
  relatedProducts: any[];
  meta: Meta;
}

const axios = AxiosInstance.create({
  baseURL: process.env.API_ENDPOINT,
});

export const prefix = '/products';
export const prefix_detail = '/product';

export const useProducts = create<ProductState>((set, get) => ({
  products: [],
  product: {},
  productCategory: [],
  assets: {},
  galleries: [],
  thumbnail: {},
  variants: [],
  isLoading: false,
  isError: false,
  relatedProducts: [],
  meta: {
    pagination: {
      page: 1,
      pageSize: 10,
      pageCount: 1,
      total: 0,
    },
  },
  getProducts: async (params?: AxiosRequestConfig) => {
    set({ isLoading: true });
    try {
      const response = await axios.get(prefix, params);
      set({ products: response.data });
      set({ isLoading: false });
      set({ isError: false });
    } catch (error) {
      console.error('Error fetching product:', error);
      set({ isLoading: false });
      set({ isError: true });
    }
  },
  getProduct: async ({
    id,
    params,
  }: {
    id: any;
    params?: AxiosRequestConfig;
  }) => {
    set({ isLoading: true });
    try {
      const response = await axios.get(`${prefix_detail}/${id}`, { params });

      const productVariants =
        response.data?.data?.attributes?.product_variants?.data?.sort(
          (a: any, b: any) =>
            a.attributes.zone_capacity - b.attributes.zone_capacity
        );

      const newProductVariants = productVariants?.map((value: any) => ({
        ...value,
        value: value?.attributes?.slug,
        label:
          value?.attributes?.zone_capacity || value?.attributes?.model || '0',
      }));

      set({ product: response.data?.data });
      set({ assets: response.data?.data?.attributes?.assets });
      set({ galleries: response.data?.data?.attributes?.galleries?.data });
      set({ thumbnail: response.data?.data?.attributes?.thumbnail?.data });
      set({ variants: newProductVariants });
      set({ isLoading: false });
      set({ isError: false });
    } catch (error) {
      console.error('Error fetching product:', error);
      set({ isLoading: false });
      set({ isError: true });
    }
  },
  getProductCategory: async ({
    id,
    params,
  }: {
    id: any;
    params?: AxiosRequestConfig;
  }) => {
    set({ isLoading: true });
    try {
      const response = await axios.get(`/product-category/${id}`, { params });

      set({ productCategory: response.data?.data });
      set({ isLoading: false });
      set({ isError: false });
    } catch (error) {
      console.error('Error fetching product:', error);
      set({ isLoading: false });
      set({ isError: true });
    }
  },
  isResetProduct: () => set({ product: undefined }),
  isResetProducts: () => set({ products: [] }),
  getRelatedProducts: async (series: string) => {
    const isDIYCassette = series === 'diy_c_mz',
      url = isDIYCassette
        ? `${BASE_URL}/models/varients/${series}/false/DIY%20OuttaSight`
        : `${BASE_URL}/models/varients/${series}/false`;

    if (!series) return;

    try {
      const data = await fetchAPI<VarientsBySeries>(
        url,
        {},
        {
          method: 'GET',
          attributes: false,
        }
      );

      if (data?.varients) {
        set({
          relatedProducts: data?.varients,
        });
      }
    } catch (error) {
      console.error('Error get product by series no :', error);
    }
  },
}));
