'use client';

import { FC, Fragment, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { usePathname } from 'next/navigation';
import Link from 'next/link';
import { useProducts } from 'stores/product';
import { useLandingPage } from 'stores/landing-page';
import TabItem from 'components/common/TabItem';
import { LandingHeader } from 'components/landing/LandingHeader';
import WhereToBuyBtn from 'components/button/where-to-buy-btn';
import { useDeviceDetect } from '@/utils/useDeviceDetect';
import { useStore } from 'zustand';
import { useMapStore } from 'stores/map/mapStore';
import { usePageTheme } from '@/stores/page-theme';
import { twMerge } from 'tailwind-merge';

interface NavItem {
  path: string;
  label: string;
}

const LayoutHeader: FC<{
  slug: string;
  [key: string]: any;
}> = ({ slug, ...props }) => {
  const isMobile = useDeviceDetect();
  const { bgColor } = useStore(usePageTheme, (state) => ({
    bgColor: state.bgColor,
  }));
  const { setModalMapOpen } = useStore(useMapStore, (state) => ({
    setModalMapOpen: state.setModalMapOpen,
  }));
  const pathname = usePathname();
  const { isLoading, isError, getProduct } = useProducts();
  const [navBGColor, setNavBGColor] = useState<string>('');

  // const slug = 'mini-stat' //'diy-4th-generation'
  const urlPrefix = `/product/${slug}`;
  const navItems: NavItem[] = [
    {
      path: `${urlPrefix}`,
      label: 'Overview',
    },
    {
      path: `${urlPrefix}/specification`,
      label: 'Specs',
    },
    {
      path: `${urlPrefix}/compare`,
      label: 'Compare',
    },
  ].filter(
    (item) =>
      !(
        (slug === 'mrcool-mini-stat' || slug === 'smart-thermostat') &&
        item.label === 'Compare'
      )
  );

  const { landingPage } = useLandingPage();

  useEffect(() => {
    getProduct({
      id: slug,
    });
  }, [slug, getProduct]);

  useEffect(() => {
    setNavBGColor(bgColor);
  }, [bgColor]);

  if (isLoading) {
    return (
      <section className={twMerge('w-full', navBGColor)}>
        <div className='mx-auto flex h-min w-full flex-col items-start justify-between px-0 lg:container md:h-16 md:flex-row md:items-center md:px-4'>
          <div className='flex w-full flex-row items-center justify-start p-5 md:w-3/12 md:p-0'>
            <Skeleton
              containerClassName='flex-1 w-full'
              className='block h-12 w-full object-contain'
              count={1}
            />
          </div>
          <div className='flex h-12 w-full flex-row items-center justify-start gap-[30px] border-t border-t-[#DCE0E3] px-5 md:h-full md:w-6/12 md:justify-center md:border-t-0 md:px-0'>
            <Skeleton
              containerClassName='flex-1 w-full'
              className='block h-12 w-full object-contain'
              count={1}
            />
          </div>
        </div>
      </section>
    );
  }

  return (
    <Fragment>
      <LandingHeader
        title={landingPage?.attributes?.title}
        contentRight={
          <>
            {!isError && (
              <WhereToBuyBtn isMobile={isMobile} setMapOpen={setModalMapOpen} />
            )}
          </>
        }
      >
        {!isError &&
          navItems.map((item: NavItem, index: number) => {
            return (
              <TabItem
                key={index}
                isActive={pathname === item.path}
                className='py-0'
              >
                <Link
                  href={item.path}
                  title={item.label}
                  className='flex h-full flex-row items-center justify-center'
                >
                  {item.label}
                </Link>
              </TabItem>
            );
          })}
      </LandingHeader>
    </Fragment>
  );
};

export default LayoutHeader;
