import { FC, ReactNode, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import Heading from '@/components/common/Heading';
import './styles/LandingHeader.scss';
import { useStore } from 'zustand';
import { ThemeBase, usePageTheme } from '@/stores/page-theme';

export interface ILandingHeader {
  title: string;
  contentRight?: ReactNode;
  rebateContent?: ReactNode;
  withoutContentCenter?: boolean;
  className?: string;
  children?: ReactNode;
  [key: string]: any;
}

export const LandingHeader: FC<ILandingHeader> = ({
  title,
  contentRight,
  rebateContent,
  withoutContentCenter = false,
  className,
  children,
  ...props
}) => {
  const { bgColor, themeBase } = useStore(usePageTheme, (state) => ({
    themeBase: state.themeBase,
    bgColor: state.bgColor,
  }));
  const [navBGColor, setNavBGColor] = useState<string>('');

  useEffect(() => {
    setNavBGColor(bgColor);
  }, [bgColor]);

  return (
    <section className={twMerge('w-full', navBGColor, className)} {...props}>
      <div className='mx-auto w-full max-w-[90rem] px-5 sm:px-10'>
        <div className='landing-header__container'>
          <div className='landing-header__title '>
            <Heading
              size={1}
              className={twMerge(
                'text-base lg:text-xl',
                themeBase === ThemeBase.light
                  ? 'text-zinc-900'
                  : 'text-zinc-300'
              )}
            >
              {title || ''}
            </Heading>
          </div>

          <div className='landing-header__navigation'>{children}</div>

          <div className='landing-header__buttons'>
            {rebateContent && (
              <div className='flex-shrink-0'>{rebateContent}</div>
            )}
            <div className='flex-shrink-0'>{contentRight}</div>
          </div>
        </div>
      </div>
    </section>
  );
};
