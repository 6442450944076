import { ThemeBase, usePageTheme } from '@/stores/page-theme';
import { FC, ReactNode, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useStore } from 'zustand';

interface TabItemProps {
  isActive?: boolean;
  className?: string;
  children?: ReactNode;
  borderColor?: string;
  [key: string]: any;
}

const TabItem: FC<TabItemProps> = ({
  isActive = false,
  className,
  children,
  borderColor = 'border-b-black',
  ...props
}) => {
  const { themeBase } = useStore(usePageTheme, (state) => ({
    themeBase: state.themeBase,
  }));

  const classNameTheme =
    themeBase === ThemeBase.light
      ? `hover:text-zinc-900 focus:text-black active:text-zinc-900 ${isActive ? `${borderColor} text-zinc-900` : 'text-zinc-500'}`
      : `hover:text-zinc-300 focus:text-black active:text-zinc-300 ${isActive ? `${borderColor} text-zinc-300` : 'text-zinc-500'}`;

  return (
    <button
      className={twMerge(
        `h-full cursor-pointer py-2 text-base font-medium transition-all`,
        classNameTheme,
        className
      )}
      {...props}
    >
      {children}
    </button>
  );
};

export default TabItem;
